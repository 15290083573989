<script>
import FilterGenerator from '@/components/general/FilterGenerator'
import LearningCardContent from '@/components/general/LearningCardContent'
import LearningCardList from '@/components/general/LearningCardList'
export default {
  name: 'WorkspaceMixedTabTemplate',
  components: {
    LearningCardContent,
    LearningCardList,
    FilterGenerator
  },
  props: {
    items: {
      type: Array
    },
    noFilter: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentMountedFilters: this.$route.params.currentMountedFilters || null,
      filterOptions: {
        actionButton: {
          vif: false,
          color: '#1200D3',
          icon: 'mdi-plus',
          text: this.$t('contents:add.content'),
          outline: true
        },
        filterFields: [
          { key: 'name_search', type: 'input_field', text: this.$t('global:search') },
          {
            key: 'content_type',
            type: 'select_field',
            text: this.$t('content:type'),
            options: this.$store.getters.getContentTypes.map(contentType => ({
              id: contentType.id,
              text: this.$t(`content.type:${contentType.alias}`)
            }))
          },
          {
            key: 'skill',
            type: 'select_field',
            text: this.$t('global:skills'),
            multiple: true,
            options: this.$store.getters.getAvailableSkills.map(skill => ({
              id: skill.id,
              text: skill.title
            })),
            firstOnly: true
          }
        ]
      }
    }
  },
  watch: {
    'currentMountedFilters' () {
      this.debounceUpdateFilters()
    },
    'selectedWorkspace' () {
      this.filterOptions.actionButton.vif = this.profileBelongsLoggedUser
    }
  },
  computed: {
    skills () {
      return this.$store.getters.getAvailableSkills.map(skill => ({
        id: skill.id,
        text: skill.title
      }))
    },
    isFilterEmpty () {
      return !this.currentMountedFilters || this.objIsEmpty(this.currentMountedFilters)
    }
  },
  methods: {
    mountFilter (filter) {
      const mountedFilter = {}
      if (filter.name_search) {
        mountedFilter.textualDescription = filter.name_search
      }
      if (filter.content_type) {
        mountedFilter.type = filter.content_type
      }
      if (filter.skill) {
        mountedFilter.skills = [filter.skill]
      }
      return mountedFilter
    },
    changedFilter (resultFilter) {
      this.currentMountedFilters = this.mountFilter(resultFilter)
    },
    loadDependencies () {
      this.$store.dispatch('attemptGetAvailableSkills', { limit: 999999 })
      !this.hasContentTypes && this.$store.dispatch('attemptGetContentTypes')
    },
    showCreateContentModal () {
      const params = { companyDomain: this.selectedWorkspace.subdomain }
      this.$router.push({ name: this.$route.name + '.content.manage', params })
    },
    debounceUpdateFilters () {
      this.debounceEvent(() => {
        this.$emit('updateList', this.currentMountedFilters)
      }, 450)
    },
    showModalAddListContent (params) {
      this.$emit('showModalAddListContent', params)
    }
  },
  created () {
    this.loadDependencies()
    this.filterOptions.actionButton.vif = this.userCanCreateContent
  }
}
</script>
<template>
  <div class="workspace-mixed--tab">
    <filter-generator v-if="!noFilter" :options="filterOptions" @change="changedFilter" multiple
      @actionBtnSubmit="showCreateContentModal" />
    <div class="workspace-mixed--contents">
      <template v-if="items && items.length">
        <div class="workspace-mixed--item" v-for="(item, index) in items" :key="index">
          <learning-card-content v-if="item.saveType === 'content'" :content="item" originWindow=".workspace"
            :allowUpdate="true" @onRemove="debounceUpdateFilters"
            @showModalAddListContent="params => showModalAddListContent(params)"></learning-card-content>
          <learning-card-list v-else :list="item" allowUpdate originWindow=".workspace"
            showItemsProgress></learning-card-list>
        </div>
      </template>
    </div>
  </div>
</template>
<style lang="scss">
.workspace-mixed--tab {
  margin-top: 20px;

  .empty-list {}

  .workspace-mixed--contents {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    margin-top: 20px;

    .workspace-mixed--item {
      display: inline-block;
      padding: 0;

      .learning-card--container {
        margin: 0;
        width: 100%;
      }
    }

    .empty-block {
      .empty-img {
        margin: 45px auto 30px;
      }

      .empty-text {
        font-family: $lato;
        font-size: $font-size-normal;
        margin-top: -20px;
        color: $neutral-medium;
      }
    }
  }

  @media only screen and (max-width: 1230px) {
    width: calc(100% - 40px);
    margin: 20px auto;

    .workspace-content--contents {
      .learning-card--container {
        flex: 1 0 33%;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .workspace-content--contents {
      justify-content: center;
      margin-top: 0;
    }
  }

  @media only screen and (max-width: 600px) {
    .workspace-content--contents {
      justify-content: center;
    }
  }
}
</style>
