<script>
import WorkspaceMixedTabTemplate from '@/components/workspace/WorkspaceMixedTabTemplate'
import Loading from '../general/Loading'
import NewContentCard from '../contents/NewContentCard'
import EmptyList from '@/components/general/EmptyList'
import ErrorList from '@/components/general/ErrorList'
export default {
  name: 'HomepageUserFavorites',
  components: { WorkspaceMixedTabTemplate, Loading, NewContentCard, EmptyList, ErrorList },
  data () {
    return {
      loading: true,
      favoriteLists: [],
      favoriteCourses: [],
      errorState: false
    }
  },
  created () {
    const requestArray = [this.$store.dispatch('attemptGetMyselfFavoriteLists')]
    if (this.selectedWorkspace.type === 'individual') requestArray.push(this.$store.dispatch('attemptGetFavoriteCourses', { aggregator: '' }))
    Promise.all(requestArray).then((data) => {
      if (data && data[0].error) this.errorState = true
      this.favoriteLists = data[0]
      this.favoriteCourses = data[1] || []
      this.loading = false
    })
  },
  methods: {
    validateWorkspace () {
      if (this.selectedWorkspace.type === 'individual') {
        this.$router.push({ name: this.selectedWorkspace.type + '.discovery.index.search', query: { type: 'contents' } })
      } else if (this.selectedWorkspace.type === 'business') {
        this.$router.push({ name: this.selectedWorkspace.type + '.discovery.index', query: { companyDomain: this.selectedWorkspace.name } })
      }
    },
    showModalAddListContent (params) {
      this.$emit('showModalAddListContent', params)
    }
  }
}
</script>
<template>
 <div class="homepage-user-favorites--container">
  <loading v-if="loading"></loading>
  <div style="padding-top: 20px" v-else>
    <div v-if="favoriteLists.length">
      <h5>{{$t('individual:saved.contents')}}</h5>
      <workspace-mixed-tab-template  :items="favoriteLists" noFilter  @showModalAddListContent="params => showModalAddListContent(params)"></workspace-mixed-tab-template>
    </div>
    <div v-if="favoriteCourses.length && this.selectedWorkspace.type === 'individual'">
      <h5 style="padding-bottom: 20px">{{$t('individual:saved.courses')}}</h5>
      <div class="contents-list--list-wrapper" v-if="favoriteCourses.length">
        <new-content-card v-for="(favoriteCourse) in favoriteCourses" :key="favoriteCourse.id" :course="favoriteCourse"></new-content-card>
      </div>
    </div>
    <error-list :title="this.$t('empty.state.text.published:error.text')" v-else-if="errorState"></error-list>
    <empty-list class="empty-list" @btnClick="validateWorkspace()" v-if="!errorState && !favoriteLists.length && (this.selectedWorkspace.type !== 'individual' || (this.selectedWorkspace.type === 'individual' && !favoriteCourses.length))"
      :title="$t('empty.state.title.favorites:empty.title')" :textOne="$t('empty.state.text:empty.text')"
      :image="'/assets/images/empty-box.png'" :buttonText="$t('empty.state.button:discovery')"
      :validateRole="true"></empty-list>
  </div>
<router-view></router-view>
</div>

</template>
<style lang="scss">
.homepage-user-favorites--container {
  padding-bottom: 40px;
  .homepage-user-favorites--banner__wrapper {
    text-align: left;
    padding: 50px 24px;
    > * {
      margin: 0;
    }
  }
}
  .contents-list--list-wrapper {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(296px, 1fr));

    &>div {
      width: 100%;
    }

    .content-card--container {
      width: 100%;
      margin: 0;
    }
  }
</style>
