import { render, staticRenderFns } from "./WorkspaceMixedTabTemplate.vue?vue&type=template&id=eef3f4e2&"
import script from "./WorkspaceMixedTabTemplate.vue?vue&type=script&lang=js&"
export * from "./WorkspaceMixedTabTemplate.vue?vue&type=script&lang=js&"
import style0 from "./WorkspaceMixedTabTemplate.vue?vue&type=style&index=0&id=eef3f4e2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports